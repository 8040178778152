import React, { useState, useEffect } from 'react';
import { savePin } from '../api';
import './PinCard.css';

function PinCard({ pin, isTrueFeedPin, isFlightMode }) {
  const [imageSrc, setImageSrc] = useState(pin.image_url);
  const [retryCount, setRetryCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const MAX_RETRIES = 3;

  const handleLike = async () => {
    try {
      setIsLiked(true);
      const pinIdToSave = isFlightMode ? pin.sourcePinId : pin.id;
      await savePin(pinIdToSave);
    } catch (error) {
      setIsLiked(false);
      console.error('Error saving pin:', error);
    }
  };

  const handleImageError = () => {
    if (retryCount < MAX_RETRIES) {
      console.warn(`Image load error for pin ${pin.id}, retrying... (${retryCount + 1}/${MAX_RETRIES})`);
      setRetryCount(prevCount => prevCount + 1);
      setImageSrc(`${pin.image_url}?retry=${retryCount + 1}`);
    } else {
      console.error(`Failed to load image for pin ${pin.id} after ${MAX_RETRIES} retries.`);
    }
  };

  const handleImageClick = () => {
    if (isTrueFeedPin) {
      window.open(`https://www.pinterest.com/pin/${pin.id}`, '_blank');
    } else {
      window.open(`https://scout.shop/u/product/${pin.id}`, '_blank');
    }
  };

  return (
    <div className="pin-card">
      <img
        src={imageSrc}
        alt={pin.description}
        onError={handleImageError}
        onClick={handleImageClick}
      />
      {(isTrueFeedPin || isFlightMode) && (
        <button onClick={handleLike} className={`like-button ${isLiked ? 'liked' : ''}`}>
          <span className="heart-icon">♥</span>
        </button>
      )}
    </div>
  );
}

export default PinCard; 