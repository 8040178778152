import axios from 'axios';

const BASE_URL = "https://sage-ai-labs--pinterest-api-endpoints";
const CACHE_EXPIRY_TIME = 30 * 60 * 1000; // Increase to 30 minutes

let similarProductsCache = {
  data: null,
  timestamp: null,
  sourceImages: [] // Track which images were used to generate these results
};

let isPrecomputing = false;
let pendingRequests = new Map();

export async function fetchTrueFeed(bookmark = null) {
  try {
    // Get thrun value from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const thrun = urlParams.get('thrun') || 'false';
    console.log("is thrun", thrun)

    const url = bookmark
      ? `${BASE_URL}-true-feed-endpoint.modal.run?bookmark=${bookmark}&thrun=${thrun}`
      : `${BASE_URL}-true-feed-endpoint.modal.run?thrun=${thrun}`;

    const response = await axios.get(url);
    const data = response.data;
    
    if (!data.resource_response?.data) {
      throw new Error('Invalid response schema: missing resource_response.data');
    }

    // Transform pins and filter out promoted content
    const transformedPins = data.resource_response.data
      .filter(pin => !pin.is_promoted)
      .map(pin => {
        // Try different paths for images based on schema
        let imageUrl;
        
        if (pin.images?.['736x']?.url) {
          imageUrl = pin.images['736x'].url;
        } else if (pin.story_pin_data?.pages?.[0]?.blocks?.[0]?.image_signature) {
          imageUrl = `https://i.pinimg.com/736x/${pin.story_pin_data.pages[0].blocks[0].image_signature}`;
        } else if (pin.image_signature) {
          imageUrl = `https://i.pinimg.com/736x/${pin.image_signature}`;
        }

        console.log('Pin image URL:', imageUrl);

        return {
          id: pin.id || pin.node_id,
          image_url: imageUrl,
          description: pin.description || pin.grid_title || '',
          metadata: {
            title: pin.title,
            link: pin.link,
            dominant_color: pin.dominant_color,
            created_at: pin.created_at,
            pinner: pin.pinner
          }
        };
      });

    return {
      pins: transformedPins,
      bookmark: data.resource_response.bookmark || null
    };
  } catch (error) {
    console.error('Error fetching true feed:', error);
    return { pins: [], bookmark: null };
  }
}

export async function savePin(pinId) {
  try {
    const response = await axios.post(`${BASE_URL}-save-pin-endpoint.modal.run`, {
      pin_id: pinId
    }, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    const data = response.data;
    if (!data.success) {
      throw new Error('Failed to save pin');
    }
    return data;
  } catch (error) {
    console.error('Error saving pin:', error);
    throw error;
  }
}

export async function processImages(imageUrls) {
  try {
    console.log('Processing images:', imageUrls);

    // Construct query parameters
    const params = new URLSearchParams();
    imageUrls.forEach((url) => {
      params.append('image_urls', encodeURIComponent(url));
    });

    const response = await axios.get(`${BASE_URL}-process-images-endpoint.modal.run?${params.toString()}`);

    const data = response.data;
    console.log('Process Images Response:', data);

    if (data.error) {
      throw new Error(`Failed to process images: ${data.error}`);
    }

    return data;
  } catch (error) {
    console.error('Error processing images:', error);
    throw error;
  }
}

export async function getEmbeddings(imageUrls) {
  try {
    console.log('Getting embeddings for:', imageUrls);
    const response = await processImages(imageUrls);

    if (!response.results) {
      throw new Error('Invalid response schema: missing results');
    }

    // Extract just the embeddings array from each result
    return response.results.map(result => result.clip_embedding);
  } catch (error) {
    console.error('Error getting embeddings:', error);
    throw error;
  }
}

export async function batchSimilaritySearch(embeddings) {
  try {
    console.log('Performing similarity search with embeddings:', embeddings);
    const response = await axios.post(`${BASE_URL}-batch-similarity-s-6fa9ad.modal.run`, {
      embeddings: embeddings,
      top_k: 1,
      namespace: "",
      filter: null,
      include_metadata: true,
      include_values: false
    }, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    const data = response.data;
    console.log('Similarity Search Response:', data);
    
    if (!data.results) {
      throw new Error('Invalid response schema: missing results');
    }
    
    // Take only the first match from each result
    const allPins = data.results.map(result => {
      const match = result.matches[0]; // Get only the first match
      const downloadedImages = JSON.parse(match.metadata.downloaded_images.replace(/'/g, '"'));
      
      return {
        id: match.id,
        image_url: downloadedImages[0],
        description: match.metadata.description || '',
        metadata: match.metadata
      };
    });

    return allPins;
  } catch (error) {
    console.error('Error performing similarity search:', error);
    return [];
  }
}

export async function fetchSimilarFeed(pins) {
  try {
    const imageUrls = pins
      .map(pin => pin.image_url)
      .filter(url => typeof url === 'string' && url.trim() !== '');

    const requestKey = JSON.stringify(imageUrls);

    // Check cache first
    const isSameSource = JSON.stringify(imageUrls) === JSON.stringify(similarProductsCache.sourceImages);
    if (
      similarProductsCache.data &&
      similarProductsCache.timestamp &&
      Date.now() - similarProductsCache.timestamp < CACHE_EXPIRY_TIME &&
      isSameSource
    ) {
      console.log('Returning cached similar products');
      return similarProductsCache.data;
    }

    // Check if there's already a pending request for these images
    if (pendingRequests.has(requestKey)) {
      console.log('Request already pending, returning existing promise');
      return pendingRequests.get(requestKey);
    }

    if (imageUrls.length === 0) {
      throw new Error('No image URLs available for processing.');
    }

    // Create and store the promise for this request
    const promise = (async () => {
      try {
        const embeddings = await getEmbeddings(imageUrls);
        const similarPins = await batchSimilaritySearch(embeddings);

        // Add source_pin_id to metadata for matching
        const updatedSimilarPins = similarPins.map((pin, index) => ({
          ...pin,
          metadata: {
            ...pin.metadata,
            source_pin_id: pins[index].id
          }
        }));

        similarProductsCache = {
          data: updatedSimilarPins,
          timestamp: Date.now(),
          sourceImages: imageUrls
        };

        return updatedSimilarPins;
      } finally {
        // Clean up the pending request
        pendingRequests.delete(requestKey);
      }
    })();

    pendingRequests.set(requestKey, promise);
    return promise;
  } catch (error) {
    console.error('Error fetching similar feed:', error);
    return similarProductsCache.data || [];
  }
}

export function clearSimilarProductsCache() {
  similarProductsCache = {
    data: null,
    timestamp: null
  };
}

export async function precomputeSimilarProducts(pins) {
  if (isPrecomputing) return;
  
  try {
    isPrecomputing = true;
    const imageUrls = pins
      .map(pin => pin.image_url)
      .filter(url => typeof url === 'string' && url.trim() !== '');

    if (imageUrls.length === 0) return;

    await processImages(imageUrls);
    const embeddings = await getEmbeddings(imageUrls);
    const similarPins = await batchSimilaritySearch(embeddings);

    similarProductsCache = {
      data: similarPins,
      timestamp: Date.now()
    };
    
    console.log('Precomputed similar products cached');
  } catch (error) {
    console.error('Error precomputing similar products:', error);
  } finally {
    isPrecomputing = false;
  }
} 